"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = _default;
function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _default(graphqlHTTPMiddleware) {
  return function (req, res) {
    var subResponses = [];
    return Promise.all(req.body.map(function (data) {
      return new Promise(function (resolve) {
        var subRequest = _objectSpread(_objectSpread({
          __proto__: req.__proto__
        }, req), {}, {
          body: data
        });
        var subResponse = _objectSpread(_objectSpread({}, res), {}, {
          status: function status(st) {
            this.statusCode = st;
            return this;
          },
          set: function set() {
            return this;
          },
          send: function send(payload) {
            resolve({
              status: this.statusCode,
              id: data.id,
              payload: payload
            });
          },
          // support express-graphql@0.5.2
          setHeader: function setHeader() {
            return this;
          },
          header: function header() {},
          write: function write(payload) {
            this.payload = payload;
          },
          end: function end(payload) {
            // support express-graphql@0.5.4
            if (payload) {
              this.payload = payload;
            }
            resolve({
              status: this.statusCode,
              id: data.id,
              payload: this.payload
            });
          }
        });
        subResponses.push(subResponse);
        graphqlHTTPMiddleware(subRequest, subResponse);
      });
    })).then(function (responses) {
      var response = '';
      responses.forEach(function (_ref, idx) {
        var status = _ref.status,
          id = _ref.id,
          payload = _ref.payload;
        if (status) {
          res.status(status);
        }
        var comma = responses.length - 1 > idx ? ',' : '';
        response += "{ \"id\":\"".concat(id, "\", \"payload\":").concat(payload, " }").concat(comma);
      });
      res.set('Content-Type', 'application/json');
      res.send("[".concat(response, "]"));
    })["catch"](function (err) {
      res.status(500).send({
        error: err.message
      });
    });
  };
}